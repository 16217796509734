import React, { useState } from 'react';
import styled, { ThemeProvider, keyframes } from 'styled-components';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { theme } from './styles/theme';
import { GlobalStyle } from './styles/GlobalStyle';
import { sendEmail } from './services/emailService';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(-45deg, ${({ theme }) => theme.colors.darker}, ${({ theme }) => theme.colors.dark});
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  color: ${({ theme }) => theme.colors.white};
  overflow-x: hidden;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  min-height: 70px;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.sm} 0;

  @media (max-width: 768px) {
    min-height: auto;
    padding: ${({ theme }) => theme.spacing.xs} 0;
  }
`;

const HeaderContent = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.lg};
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: ${({ theme }) => theme.spacing.sm};
    padding: ${({ theme }) => theme.spacing.sm};
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`;

const ServiceNav = styled.nav`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.md};
  padding: 0 ${({ theme }) => theme.spacing.md};
  
  img {
    height: 40px;
    width: auto;
    transition: all ${({ theme }) => theme.transitions.default};
  }

  @media (max-width: 1024px) {
    gap: ${({ theme }) => theme.spacing.sm};
    img {
      height: 35px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: ${({ theme }) => theme.spacing.xs};
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 -${({ theme }) => theme.spacing.sm};
    padding: 0 ${({ theme }) => theme.spacing.sm};
    justify-content: flex-start;
    
    img {
      height: 30px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ServiceIcon = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  transition: all ${({ theme }) => theme.transitions.default};
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.xs};

  &:hover {
    transform: translateY(-2px);
    
    img {
      filter: brightness(1.2);
      transform: scale(1.1);
    }
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  
  img {
    height: 40px;
    transition: transform ${({ theme }) => theme.transitions.default};
  }

  @media (max-width: 1024px) {
    img {
      height: 35px;
    }
  }

  @media (max-width: 768px) {
    img {
      height: 30px;
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
`;

const NavLink = styled.a`
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.primary};
  transition: all ${({ theme }) => theme.transitions.default};
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: width ${({ theme }) => theme.transitions.default};
  }
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    
    &:after {
      width: 100%;
    }
  }
`;

const Hero = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xl} ${({ theme }) => theme.spacing.lg};
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
`;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 4rem;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  line-height: 1.2;
  background: linear-gradient(45deg, ${({ theme }) => theme.colors.primary}, #ff8a8a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${gradientAnimation} 8s ease infinite;
`;

const Subtitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const CTAButton = styled.button`
  background: linear-gradient(45deg, ${({ theme }) => theme.colors.primary}, #ff8a8a);
  color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.lg};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all ${({ theme }) => theme.transitions.default};
  position: relative;
  overflow: hidden;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, transparent, rgba(255,255,255,0.2), transparent);
    background-size: 200% 100%;
    animation: ${gradientAnimation} 3s ease infinite;
  }
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(254, 104, 105, 0.3);
  }
`;

const ServicesSection = styled.section`
  padding: ${({ theme }) => theme.spacing.xl} ${({ theme }) => theme.spacing.lg};
  max-width: 1200px;
  margin: 0 auto;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: ${({ theme }) => theme.spacing.xl};
  padding: ${({ theme }) => theme.spacing.xl};
  max-width: 1400px;
  margin: 0 auto;
`;

const ServiceBox = styled.div`
  background: rgba(255, 255, 255, 0.03);
  border-radius: 20px;
  padding: ${({ theme }) => theme.spacing.xl};
  transition: all ${({ theme }) => theme.transitions.default};
  border: 1px solid rgba(254, 104, 105, 0.1);
  
  &:hover {
    transform: translateY(-10px);
    background: rgba(255, 255, 255, 0.05);
    border-color: ${({ theme }) => theme.colors.primary};
  }
  
  h3 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.5rem;
    margin-bottom: ${({ theme }) => theme.spacing.md};
    background: linear-gradient(45deg, ${({ theme }) => theme.colors.primary}, #ff8a8a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  p {
    color: ${({ theme }) => theme.colors.gray};
    line-height: 1.6;
  }

  i {
    font-size: 2rem;
    margin-bottom: ${({ theme }) => theme.spacing.md};
    background: linear-gradient(45deg, ${({ theme }) => theme.colors.primary}, #ff8a8a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const ContactSection = styled.section`
  padding: ${({ theme }) => theme.spacing.xl};
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.xl};
  background: rgba(255, 255, 255, 0.03);
  padding: ${({ theme }) => theme.spacing.xl};
  border-radius: 20px;
  border: 1px solid rgba(254, 104, 105, 0.1);
`;

const Input = styled.input`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(254, 104, 105, 0.2);
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primary};
  transition: all ${({ theme }) => theme.transitions.default};
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
    background: rgba(255, 255, 255, 0.08);
  }
`;

const TextArea = styled.textarea`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(254, 104, 105, 0.2);
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primary};
  min-height: 150px;
  transition: all ${({ theme }) => theme.transitions.default};
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
    background: rgba(255, 255, 255, 0.08);
  }
`;

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [status, setStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('sending');
    setErrorMessage('');

    if (!executeRecaptcha) {
      setStatus('error');
      setErrorMessage('reCAPTCHA not loaded');
      return;
    }

    try {
      const token = await executeRecaptcha('contact');
      const result = await sendEmail({ ...formData, token });

      if (result.success) {
        setStatus('success');
        setFormData({ name: '', email: '', subject: '', message: '' });
      } else {
        setStatus('error');
        setErrorMessage(result.error || 'Failed to send email');
      }
    } catch (error) {
      setStatus('error');
      setErrorMessage('Failed to send email. Please try again later.');
    }
  };

  if (status === 'success') {
    return (
      <SuccessMessage>
        <h3>Message Sent Successfully!</h3>
        <p>Thank you for contacting us. We'll get back to you soon.</p>
        <button onClick={() => setStatus('idle')}>Send Another Message</button>
      </SuccessMessage>
    );
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Input
        type="text"
        placeholder="Name"
        value={formData.name}
        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        required
      />
      <Input
        type="email"
        placeholder="Email"
        value={formData.email}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        required
      />
      <Input
        type="text"
        placeholder="Subject"
        value={formData.subject}
        onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
        required
      />
      <TextArea
        placeholder="Message"
        value={formData.message}
        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
        required
      />
      {status === 'error' && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Button type="submit" disabled={status === 'sending'}>
        {status === 'sending' ? 'Sending...' : 'Send Message'}
      </Button>
    </StyledForm>
  );
};

const SuccessMessage = styled.div`
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  color: #fff;

  h3 {
    color: #00ff00;
    margin-bottom: 1rem;
  }

  button {
    background: #00ff00;
    color: #000;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
    font-family: 'Orbitron', sans-serif;
    transition: all 0.3s ease;

    &:hover {
      background: #00cc00;
    }
  }
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  margin-bottom: 1rem;
  text-align: center;
`;

const Button = styled(CTAButton)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const SolutionsSection = styled.section`
  padding: ${({ theme }) => theme.spacing.xl} ${({ theme }) => theme.spacing.lg};
  max-width: 1200px;
  margin: 0 auto;
`;

const SolutionDescription = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  padding: ${({ theme }) => theme.spacing.xl};
  background: rgba(255, 255, 255, 0.03);
  border-radius: 20px;
  border: 1px solid rgba(254, 104, 105, 0.1);

  p {
    color: ${({ theme }) => theme.colors.gray};
    line-height: 1.8;
    font-size: 1.2rem;
    margin-top: ${({ theme }) => theme.spacing.md};
  }
`;

const PortfolioGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.xl};
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xl} 0;
`;

const PortfolioItem = styled.div`
  background: rgba(255, 255, 255, 0.03);
  overflow: hidden;
  border: 1px solid rgba(254, 104, 105, 0.1);
  transition: all ${({ theme }) => theme.transitions.default};
  height: 40vh;
  width: 80%;
  display: flex;
  position: relative;
  border-radius: 20px;

  &:hover {
    transform: translateY(-10px);
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 10px 20px rgba(254, 104, 105, 0.2);
    
    img {
      transform: scale(1.05);
    }

    .content {
      background: rgba(0, 0, 0, 0.8);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    padding: ${({ theme }) => theme.spacing.xl};
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all ${({ theme }) => theme.transitions.default};
    border-radius: 20px;

    h3 {
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-size: 2.5rem;
      margin-bottom: ${({ theme }) => theme.spacing.md};
      background: linear-gradient(45deg, ${({ theme }) => theme.colors.primary}, #ff8a8a);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: ${gradientAnimation} 8s ease infinite;
    }

    p {
      color: ${({ theme }) => theme.colors.gray};
      line-height: 1.8;
      font-size: 1.2rem;
      max-width: 800px;
      font-family: ${({ theme }) => theme.fonts.primary};
    }
  }

  @media (max-width: 768px) {
    width: 95%;
    height: 50vh;

    .content {
      h3 {
        font-size: 2rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }
`;

const App: React.FC = () => {
  const handleFormSubmit = async (data: any) => {
    const response = await sendEmail(data);
    if (!response.success) {
      throw new Error(response.error);
    }
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_SITE_KEY || ''}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Container>
          <Header>
            <HeaderContent>
              <Logo>
                <img src={process.env.PUBLIC_URL + '/Letters@2x.png'} alt="Hudson Riggs Systems" />
              </Logo>
              <ServiceNav>
                <ServiceIcon href="https://cpanel.hudsonriggs.systems" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + '/cpanel.png'} alt="cPanel" />
                </ServiceIcon>
                <ServiceIcon href="https://portal.hudsonriggs.systems" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + '/whmcs.png'} alt="WHMCS" />
                </ServiceIcon>
                <ServiceIcon href="https://pterodactyl.hudsonriggs.systems" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + '/pterodactyl.png'} alt="Pterodactyl" />
                </ServiceIcon>
                <ServiceIcon href="https://gitea.hudsonriggs.systems" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + '/gitea.png'} alt="Gitea" />
                </ServiceIcon>
                <ServiceIcon href="https://cloud.hudsonriggs.systems" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + '/nextcloud.png'} alt="Cloud" />
                </ServiceIcon>
                <ServiceIcon href="https://proxmox.hudsonriggs.systems" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + '/proxmox.png'} alt="Proxmox" />
                </ServiceIcon>
                <ServiceIcon href="https://jellyfin.hudsonriggs.systems" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + '/jellyfin.png'} alt="Jellyfin" />
                </ServiceIcon>
                <ServiceIcon href="https://jellyseerr.hudsonriggs.systems" target="_blank" rel="noopener noreferrer">
                  <img src={process.env.PUBLIC_URL + '/jellyseerr.png'} alt="Jellyseerr" />
                </ServiceIcon>
              </ServiceNav>
              <Nav>
                <NavLink href="#services">Services</NavLink>
                <NavLink href="#solutions">Solutions</NavLink>
                <NavLink href="#contact">Contact</NavLink>
              </Nav>
            </HeaderContent>
          </Header>
          <Hero>
            <Title>Elevating Gaming Communities Through Innovation</Title>
            <Subtitle>
              We craft bespoke software solutions and infrastructure that empower gaming communities to thrive. 
              From custom mods to scalable hosting solutions, we deliver excellence in every pixel and line of code.
            </Subtitle>
            <CTAButton>Explore Our Solutions</CTAButton>
          </Hero>
          
          <ServicesSection id="services">
            <Title>Our Services</Title>
            <ServicesGrid>
              <ServiceBox>
                <i className="fas fa-code"></i>
                <h3>Custom Mod Development</h3>
                <p>Specialized game modifications tailored to your community's needs. Our expert developers create unique gaming experiences that keep players engaged and excited.</p>
              </ServiceBox>
              <ServiceBox>
                <i className="fas fa-server"></i>
                <h3>Game Server Hosting</h3>
                <p>High-performance, low-latency game server hosting with 24/7 monitoring and automatic scaling to handle your community's growth.</p>
              </ServiceBox>
              <ServiceBox>
                <i className="fas fa-shield-alt"></i>
                <h3>DDoS Protection</h3>
                <p>Enterprise-grade DDoS protection specifically designed for gaming infrastructure, ensuring your servers stay online during critical moments.</p>
              </ServiceBox>
              <ServiceBox>
                <i className="fas fa-users-cog"></i>
                <h3>Community Management Tools</h3>
                <p>Custom-built administrative and moderation tools that help you manage your community effectively and efficiently.</p>
              </ServiceBox>
              <ServiceBox>
                <i className="fas fa-chart-line"></i>
                <h3>Analytics & Insights</h3>
                <p>Comprehensive analytics and reporting tools to track player engagement, server performance, and community growth metrics.</p>
              </ServiceBox>
              <ServiceBox>
                <i className="fas fa-hands-helping"></i>
                <h3>24/7 Technical Support</h3>
                <p>Round-the-clock technical support from gaming infrastructure experts who understand your unique needs and challenges.</p>
              </ServiceBox>
            </ServicesGrid>
          </ServicesSection>

          <SolutionsSection id="solutions">
            <Title>Our Solutions</Title>
            <SolutionDescription>
              <h3>Comprehensive Gaming Infrastructure Solutions</h3>
              <p>
                At Hudson Riggs Systems, we specialize in delivering end-to-end solutions for gaming communities.
                Our expertise spans from custom mod development to scalable server infrastructure, ensuring your
                community has everything it needs to thrive in the digital gaming landscape.
              </p>
            </SolutionDescription>
            <PortfolioGrid>
              <PortfolioItem>
                <img src={process.env.PUBLIC_URL + '/portfolio-1.jpg'} alt="Project 1" />
                <div className="content">
                  <h3>Project Alpha</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
                  </p>
                </div>
              </PortfolioItem>
              <PortfolioItem>
                <img src={process.env.PUBLIC_URL + '/portfolio-2.jpg'} alt="Project 2" />
                <div className="content">
                  <h3>Project Beta</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
                  </p>
                </div>
              </PortfolioItem>
              <PortfolioItem>
                <img src={process.env.PUBLIC_URL + '/portfolio-3.jpg'} alt="Project 3" />
                <div className="content">
                  <h3>Project Gamma</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
                  </p>
                </div>
              </PortfolioItem>
            </PortfolioGrid>
          </SolutionsSection>

          <ContactSection id="contact">
            <Title>Get in Touch</Title>
            <Subtitle>Have a project in mind? Let's discuss how we can help your gaming community thrive.</Subtitle>
            <ContactForm />
          </ContactSection>
        </Container>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App; 