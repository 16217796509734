export const theme = {
  colors: {
    primary: '#fe6869',
    dark: '#0c090a',
    darker: '#141414',
    white: '#ffffff',
    lightGray: '#f5f5f5',
    gray: '#666666',
  },
  fonts: {
    primary: "'Inter', sans-serif",
    secondary: "'Space Grotesk', sans-serif",
  },
  spacing: {
    xs: '0.5rem',
    sm: '1rem',
    md: '2rem',
    lg: '3rem',
    xl: '4rem',
  },
  breakpoints: {
    mobile: '576px',
    tablet: '768px',
    desktop: '1024px',
    wide: '1200px',
  },
  transitions: {
    default: '0.3s ease',
  },
} as const; 